.comments-container {
  max-height: 610px;
  margin-top: 5px;
  overflow: auto;
  border: 5px solid #e9e9e9;
  border-radius: 5px;
}

.facebook-live {
  margin-bottom: 10px;
  margin-top: 5px;
}

.article-comments-container {
  border: 5px solid #e9e9e9;
  border-radius: 5px;
  box-sizing: border-box;
}

.fb-comments-article-container {
  max-height: 300px;
  width: 100%;
  overflow: auto;
  border: 5px solid #e9e9e9;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

@media only screen and (max-width: 959px) {
  .comments-container {
    margin-bottom: 10px;
  }
}
