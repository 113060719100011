.ads-banner {
  width: 100%;
  max-width: 100%;
}
.ad-banner-container {
  height: inherit;
}

.ads-mosaic {
  margin: 0 auto;
  padding: 7px;
}
