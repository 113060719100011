html {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  font-size: 18px;
}
body {
  margin: 0;

  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
li {
  padding: 0;
  margin: 0;
}

.art-slider-text {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
}

figure {
  margin: 0;
  width: 100% !important;
  height: auto;
}

figure img {
  width: 100%;
  height: auto;
}

.hamburger-react {
  width: 40px !important;
  height: 40px !important;
}

.search_input {
  max-width: 100%;
  color: #fff;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  font-size: 17px;
}

.search_input::placeholder {
  color: #fff;
  font-size: 17px;
}
.search_input + span {
  position: absolute;
  top: 4px;
  right: 65px;
  color: #fff;
  font-size: 13px;
}
