.max-text-1 {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.max-text-2 {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.max-text-3-2 {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.max-text-2-1 {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: center;
}

.max-text-7-4-2 {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
}

/* ////lg/// */
@media only screen and (max-width: 1500px) {
  .max-text-7-4-2 {
    -webkit-line-clamp: 4;
  }
}
/* ////md///// */
@media only screen and (max-width: 959px) {
  .max-text-3-2 {
    -webkit-line-clamp: 2;
  }

  .max-text-7-4-2 {
    -webkit-line-clamp: 2;
  }
  .max-text-2-1 {
    -webkit-line-clamp: 1;
  }

  .display-none {
    display: none;
  }
}
/* ///sm/// */
@media only screen and (max-width: 599px) {
}

.tiktok-icon {
  cursor: pointer;
  background-color: #69c9d0;
  width: 37px;
  height: 37px;
  border-radius: 5px;
  transition: all 0.1s ease;
}
.tiktok-icon:hover {
  transition: all 0.1s ease;
  transform: scale(0.9);
}
