.video-text {
  text-align: center;
}
.slick-video-item {
  direction: rtl;
  text-align: right;
  position: relative;
  border-left: 1px dashed;
  border-color: rgba(0, 0, 0, 0.14);
}

.slick-prev::before {
  font-size: 25px;
  color: #003d79;
}

.slick-next::before {
  font-size: 25px;
  color: #003d79;
}

.video-item {
  margin: 0 2px 0 2px;
}

.slick-container {
  margin: 10px auto;
  align-items: center;
  width: 85%;
  justify-content: center;
}
.slick-video-wrapper {
  border-radius: 5px;
  box-sizing: content-box;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  width: 98%;
}
.slick-prev {
  right: -25px !important;
}
.slick-next {
  left: -25px !important;
}
@media only screen and (max-width: 959px) {
  .slick-video-wrapper {
    border-radius: 5px;
    box-sizing: content-box;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    width: 98%;
  }
  .slick-prev {
    right: -20px !important;
  }

  .slick-next {
    left: -20px !important;
  }
}

@media only screen and (max-width: 599px) {
  .slick-video-wrapper {
    margin: 0 auto;
    padding: 0;
  }
  .slick-prev {
    right: -22px !important;
  }

  .slick-next {
    left: -28px !important;
  }
}

.slick-video-wrapper .fb_iframe_widget_fluid_desktop iframe {
  min-width: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px !important;
  border: 5px solid #e9e9e9 !important;
  box-sizing: border-box !important;
}

.slick-video-wrapper .fb_iframe_widget_fluid span {
  min-width: 0 !important;
  width: 100% !important;
  min-height: 100% !important;
  height: 100% !important;
}
