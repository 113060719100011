.ad1 {
  padding: 0 0 10px 0;
}
.ad2 {
  padding-left: 5px;
  padding-bottom: 5px;
}
.ad3 {
  padding-right: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 959px) {
  .ad1 {
    padding: 0 0;
    padding-left: 3px;
  }
  .ad2 {
    padding-right: 3px;
    padding-bottom: 0px;
    padding-left: 0;
  }
  .ad3 {
    padding-top: 6px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }

  .sub-article-ads {
    padding: '100px';
  }
}
